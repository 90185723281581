import React, { useEffect } from "react";
import NavBar from "../../components/navbar/NavBar"
import CardQuestion from "../../components/card/CardQuestion";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useContext, useState } from "react";


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    container :{
        display: 'flex',
        flexDirection : 'column',
        overflow : 'auto',
      
        // list-style: none,
        // padding: 0,
        // margin: 0,
      }
 
  }));
export default function QuestionList (props){
    const classes = useStyles();

    const [isLoading, setIsLoading] = React.useState(true);
    // const [data, setData] = React.useState(null);
    const [activeCard, setActiveCard] = useState(null); // Track the currently active card

    const [maxScore, setMaxScore] = React.useState(0);

    const {
        dataAssessment,
        listQuestion,
        dataMember,
        timer,
        startTest,
        review,
        uniqe,
        question_no,
    
      } = useLocation().state;
      const [assessmentScore, setAssessmentScore] = React.useState([]);
      const [scoreObject, setScoreObject] = React.useState({
        arrayQuestion: [],
        unsolvedQuestion: 0,
        totalQuestion: 0,
      });
      React.useEffect(() => {
        var a = localStorage.getItem(
            `${dataAssessment.assessment.assessment_code + dataMember.id}_results`
        )

        // console.log(a)
        // console.log(`${dataAssessment.assessment_code + dataMember.id}_results`)
        //console.log(JSON.stringify(localStorage.getItem(`results`)) + "results");
        if (
          localStorage.getItem(
            `${dataAssessment.assessment.assessment_code + dataMember.id}_results`
          )
        ) {
          let results = localStorage.getItem(
            `${dataAssessment.assessment.assessment_code + dataMember.id}_results`
          );

          
          setAssessmentScore(JSON.parse(results));
        }
      }, []);
    
      React.useEffect(() => {
        let result = Object.values(assessmentScore).flat();
    
        if (result.length == 0) {
          let tempScore = [];
    
          listQuestion.forEach((item, index) => {
            let maxScore = 0;
    
            for (let i = 0; i < item.test_case.length; i++) {
              maxScore += Number(item.test_case[i].score);
            }
    
            let question = {
              question_code: item.question_code,
              question_name: item.data_question.question_name,
              result: "-",
              maxScore: maxScore,
              language: "-",
            };
    
            tempScore.push(question);
          });
    
          setScoreObject({
            arrayQuestion: [...tempScore],
            unsolvedQuestion: listQuestion.length,
            totalQuestion: listQuestion.length,
          });
        } else {
          let tempScore = [];
          let solvedQuestion = 0;
          listQuestion.forEach((item, index) => {
            let maxScore = 0;
    
            for (let i = 0; i < item.test_case.length; i++) {
              maxScore += Number(item.test_case[i].score);
            }
            let question = {
              question_code: item.question_code,
              question_name: item.data_question.question_name,
              result: "-",
              maxScore: maxScore,
              language: "-",
            };
    
            result.forEach((item) => {
              // resultScore += `Score question ${item.question_code} = ${item.score} (${item.language_code}) <br />`;
              if (item.question_code == question.question_code) {
                question.result = item.score;
                question.language = item.language_code;
                solvedQuestion++;
              }
            });
    
            tempScore.push(question);
          });
    
          setScoreObject({
            arrayQuestion: [...tempScore],
            unsolvedQuestion: listQuestion.length - solvedQuestion,
            totalQuestion: listQuestion.length,
          });
        }
      }, [assessmentScore, setAssessmentScore]);
 
      


    return(
        <>
        
        <NavBar assesment={dataAssessment} />      
        <Container className={classes.container}>
        {listQuestion?.map((question,index) => (
            
            <CardQuestion
            activeCard = {activeCard}
            setActiveCard = {setActiveCard}
            key = {index +1}
            num={index +1}
            questionTitle={question.data_question.question_name}
            score={assessmentScore[index +1]?.score}
            assessment = {dataAssessment}
            test_case = {question.test_case}
            />
        ))}
            
        
        </Container>
            
        </>




    )
}