import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    instruction: {
        display: "flex",
        maxHeight: "70vh",
        overflow: "auto",
        padding: "10px",
        flexDirection: "column",
        fontWeight: "normal",
    },
});

const instruction = `
<p>Galih and Ratna married during the COVID 19 period and only invited the families of both partners. they rented a number of minibuses to pick up all of their families to go to the wedding.</p><p>But during COVID 19, the government held a PSBB program to reduce the impact of the spread of the virus. Each mini bus can only carry at most 4 passengers. What a minimum number of buses will they need to rent if all members of each family should ride in the same Busses. (one bus can't take more than two family)</p><p><b>Input : </b></p><p>The first line contains integer n — the number of families. </p><p>The second line contains a sequence of integers. The integers are separated by a space, each integer is the number of members in the family.</p><p><b>Output :</b></p><p>Print the single number — the minimum number of buses necessary to drive all family to the Wedding.</p><p>Print “Input must be equal with count of family” if input number of family is not equal with count of family.</p>
`;

const InstructionGuide = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.instruction} instruction-guide`}>
            <div dangerouslySetInnerHTML={{ __html: instruction }} />
        </div>
    );
    return;
};

export default InstructionGuide;
