import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AceEditor from "react-ace";
import unescapeJs from "unescape-js";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "scroll",
    height: "calc(100vh - 30vh)",
  },
  title: {
    fontSize: "23px",
    fontWeight: "600",
    fontFamily: "'Poppins', sansSerif",
    fontStyle: "normal",
    lineHeight: "34px",
    marginBottom: "8px",
  },
}));
function Solution({ languageId, listQuestion, currentQuestion }) {
  const classes = useStyles();
  const [solution, setSolution] = useState("");
  const [indexSolution, setIndexSolution] = useState(0);

  const changeInput = (event) => {
    console.log(event);
    let value = event.target.value;
    setIndexSolution(value);
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={0}>
            <Grid item xs={6} lg={3}>
              <h3 className={classes.title}>Language</h3>
            </Grid>
            <Grid item xs={6} lg={9}>
              <select
                value={indexSolution}
                onChange={changeInput}
                id="tags"
                className="form-control form-inline mb-2 mx-2 language"
              >
                {listQuestion[currentQuestion - 1].solutions.map(
                  (item, index) => (
                    <option
                      key={index}
                      value={index}
                    >{`${item.detail_language.name}`}</option>
                  )
                )}
              </select>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <AceEditor
            id="source"
            readOnly={true}
            className=" source"
            placeholder="Source Code"
            mode={listQuestion[currentQuestion - 1].solutions[0].language_code}
            theme="dracula"
            name="solution"
            onChange={() => {}}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={unescapeJs(
              listQuestion[currentQuestion - 1].solutions[indexSolution]
                ? listQuestion[currentQuestion - 1].solutions[indexSolution]
                    .solution_code
                : listQuestion[currentQuestion - 1].solutions[0].solution_code
            )}
            width="100%"
            height="60vh"
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 4,
              wrap: true,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Solution;
