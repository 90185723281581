import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LogoCode from "../../assets/icons/code-image.png";
import Tooltip from "@material-ui/core/Tooltip";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles({
    containerDefault: {
        height: "70vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    containerResult: {
        fontFamily: "'JetBrains Mono', monospace",
        height: "70vh",
        overflowX: "hidden",
        overflowY: "scroll",
    },
    titleResult: {
        fontFamily: "'Poppins', sansSerif",
    },
    textResult: {
        marginRight: "5px",
        fontStyle: "normal",
        fontWeight: "500",
    },
});

const sampleInput = `5 1 2 4 3 3`;
const yourOutput = `Minimum bus required is : 0 \r\n Minimum bus required is : 2`;
const expectedOutput = `Minimum bus required is : 8`;
const testCase = `
<p style={{ margin: "10px 0px" }}><span className={classes.textResult}>Test Case 1 : Success</span><CheckCircleIcon style={{ color: "#0F996B"}} /></p><p style={{ margin: "10px 0px" }}><span className={classes.textResult}>Test Case 2 : Failed</span><CheckCircleIcon style={{ color: "#0F996B"}} /></p><p style={{ margin: "10px 0px" }}><span className={classes.textResult}>Test Case 3 : Success</span><CheckCircleIcon style={{ color: "#0F996B"}} /></p><p style={{ margin: "10px 0px" }}><span className={classes.textResult}>Test Case 4 : Success</span><CheckCircleIcon style={{ color: "#0F996B"}} /></p>`;

const ResultGuide = () => {
    const classes = useStyles();

    return (
        <div className={classes.containerResult}>
            <div className="sample-input-guide">
                <p className={classes.titleResult}>Sample Input</p>
                <div
                    style={{
                        width: "100%",
                        backgroundColor: "#CFD6E5",
                        minHeight: "100px",
                        padding: "10px 15px",
                    }}
                >
                    <span
                        style={{ whiteSpace: "pre-line" }}
                        className={classes.textResult}
                    >
                        {sampleInput}
                    </span>
                </div>
            </div>
            <div className="your-output-guide">
                <p
                    className={classes.titleResult}
                    style={{ marginTop: "10px" }}
                >
                    Your Output
                </p>
                <div
                    style={{
                        width: "100%",
                        backgroundColor: "#CFD6E5",
                        minHeight: "100px",
                        padding: "10px 15px",
                    }}
                >
                    <span
                        style={{ whiteSpace: "pre-line" }}
                        className={classes.textResult}
                    >
                        {yourOutput}
                    </span>
                </div>
            </div>
            <div className="expected-output-guide">
                <p
                    className={classes.titleResult}
                    style={{ marginTop: "10px" }}
                >
                    Expected Output
                </p>
                <div
                    style={{
                        width: "100%",
                        backgroundColor: "#CFD6E5",
                        minHeight: "100px",
                        padding: "10px 15px",
                    }}
                >
                    <span
                        style={{ whiteSpace: "pre-line" }}
                        className={classes.textResult}
                    >
                        {expectedOutput}
                    </span>
                </div>
            </div>
            <div className="test-case-guide">
                <p
                    className={classes.titleResult}
                    style={{ marginTop: "10px" }}
                >
                    <span style={{ margin: "0px auto" }}>Test Case</span>{" "}
                    <Tooltip
                        title="System will input some test case to your code, if you have a failed test case, you can check again the requirement from the question"
                        placement="right-end"
                        arrow
                    >
                        <ErrorOutlineIcon />
                    </Tooltip>
                </p>
                <div
                    style={{
                        width: "100%",
                        backgroundColor: "#CFD6E5",
                        minHeight: "100px",
                        padding: "10px 15px",
                        fontWeight: "normal",
                    }}
                >
                    <div dangerouslySetInnerHTML={{ __html: testCase }} />
                </div>
            </div>
        </div>
    );
};

export default ResultGuide;
